import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import elixatec_white from '../../assets/img/elixatec_white.png';
import sprite from '../../assets/icons/sprite.svg';
import './Footer.scss';

class Footer extends Component {
	socialItems = [
		{ id: 1, name: 'icon-facebook', link: 'https://facebook.com' },
		{ id: 2, name: 'icon-instagram', link: 'https://instagram.com' },
		{ id: 3, name: 'icon-linkedin2', link: 'https://linkedin.com' },
		{ id: 4, name: 'icon-twitter', link: 'https://twitter.com' }
	];
	render() {
		return (
			<footer className='footer'>
				<div className='footer__logo-box'>
					<img
						src={elixatec_white}
						alt='footer logo'
						className='footer__logo'
					/>
				</div>
				<div className='footer__navigation'>
					<ul className='footer__list'>
						<li className='footer__item'>
							<Link to='/projects' className='footer__link'>
								our work
							</Link>
						</li>
						<li className='footer__item'>
							<Link to='/about' className='footer__link'>
								services
							</Link>
						</li>
						<li className='footer__item'>
							<Link to='/contact' className='footer__link'>
								our story
							</Link>
						</li>
						<li className='footer__item'>
							<Link to='/team' className='footer__link'>
								the team
							</Link>
						</li>
					</ul>
				</div>
				<div className='footer__social'>
					<ul className='footer__social--icons'>
						{this.socialItems.map((socialItem) => (
							<li
								className='footer__social--list'
								key={socialItem.id}>
								<a
									className='footer__social--link'
									rel='noopener noreferrer'
									target='_blank'
									href={socialItem.link}>
									<svg className='footer__social--icon '>
										<use
											xlinkHref={`${sprite}#${socialItem.name}`}
										/>
									</svg>
								</a>
							</li>
						))}
					</ul>
				</div>
				<div className='footer__description u-center-text'>
					<p className='text__content--normal text__content--boulder'>
						Elixatec Limited was founded in 2018 and constitutes an
						independent, private company of consulting services in
						the sectors of core technology, fintech, digital
						disruption and international collaborations. Each of our
						senior consultants bring on board over 15 years’
						experience in design and delivery of innovative and
						large-scale technologies in East Africa.
					</p>
					<p className='text__content--normal text__content--boulder u-margin-top-medium'>
						info@elixatec.co.ke
					</p>
				</div>
				<p className='footer__copyright'>
					All rights reserved | &copy; {new Date().getFullYear()}{' '}
					Elixatec Limited.
				</p>
			</footer>
		);
	}
}
export default Footer;
