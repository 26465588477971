import React from 'react';

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Index from './Home/Index';
import Work from './Work/Work';
import Team from './Team/Team';

import Error404 from '../components/404/Error404';

const App = () => {
	return (
		<Router>
			<React.Fragment>
				<Navbar />
				<main>
					<Switch>
						<Route exact path='/'>
							<Index />
						</Route>
						<Route exact path='/work' component={Work} />
						<Route exact path='/team' component={Team} />
						<Route exact path='/404' component={Error404} />
						<Redirect from='*' to='/404' />
					</Switch>
				</main>
				<Footer />
			</React.Fragment>
		</Router>
	);
};

export default App;
