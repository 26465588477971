import React, { Component } from 'react';
import flat_image from '../../assets/img/flat.jpg';
import workspace_image from '../../assets/img/workspace.jpg';
import artificial_image from '../../assets/img/artificial.jpg';
import './Work.scss';

class Work extends Component {
	cardItems = [
		{
			id: 1,
			image: flat_image,
			alt: 'flat',
			workTitle: 'Software Development',
			workDescription:
				'To meet the changing demands in the digital revolution through our passion and commitment to our clients.'
		},
		{
			id: 2,
			image: workspace_image,
			alt: 'workspace',
			workTitle: 'Software Development',
			workDescription:
				'To meet the changing demands in the digital revolution through our passion and commitment to our clients.'
		},
		{
			id: 3,
			image: artificial_image,
			alt: 'artficial',
			workTitle: 'Software Development',
			workDescription:
				'To meet the changing demands in the digital revolution through our passion and commitment to our clients.'
		}
	];
	render() {
		return (
			<div className='work'>
				{this.cardItems.map((cardItem) => (
					<div className='item ' key={cardItem.id}>
						<div className='work__card'>
							<figure className='work__card--figure'>
								<img
									className='work__card--image'
									src={cardItem.image}
									alt={cardItem.alt}
								/>
							</figure>
							<div className='work__card--details'>
								<h3 className='heading__secondary heading__secondary--white u-margin-bottom-small'>
									{cardItem.workTitle}
								</h3>
								<p className='text__content text__content--white'>
									{cardItem.workDescription}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}
export default Work;
