import React, { Component } from 'react';

import './Features.scss';

import business from '../../assets/img/1032.jpg';

class Features extends Component {
	render() {
		return (
			<div className={'features'}>
				<div className={'features__illustration'}>
					<img
						src={business}
						className={`features__illustration-img`}
						alt='Illustration'
					/>
				</div>
				<div className={'features__cta'}>
					<h1
						className={
							'heading__secondary heading__secondary--white u-margin-bottom-small'
						}>
						We work closely with you to:
					</h1>
					<ul
						className={
							'features__list text__content--normal text__content--regentGray u-margin-bottom-medium'
						}>
						<li className='features__list--item'>
							Reach new and game-changing levels of performance by
							modernizing your IT capabilities, including your
							systems architecture, operating model so your
							company is ready to pursue its digital future.
						</li>
						<li className='features__list--item'>
							Improve the value that IT brings to the business, by
							identifying the resources and capabilities needed to
							generate lasting results. Focus your investments on
							what matters most, ensure the right people and
							processes are in place.
						</li>
						<li className='features__list--item'>
							Make IT a source of success instead of a liablity by
							developing a robust IT Merger Integration.
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default Features;
