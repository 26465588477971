import React, { Component } from 'react';
import one from '../../assets/avatar/1.jpg';
import two from '../../assets/avatar/2.jpg';
import three from '../../assets/avatar/3.png';
import four from '../../assets/avatar/4.png';
import five from '../../assets/avatar/5.png';
import six from '../../assets/avatar/6.jpg';
import seven from '../../assets/avatar/7.png';
import eight from '../../assets/avatar/8.png';
import nine from '../../assets/avatar/9.jpg';
import ten from '../../assets/avatar/10.jpg';

import './Team.scss';

class Team extends Component {
	teamMembers = [
		{
			memberId: 1,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: one
		},
		{
			memberId: 2,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: two
		},
		{
			memberId: 3,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: three
		},
		{
			memberId: 4,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: four
		},
		{
			memberId: 5,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: five
		},
		{
			memberId: 6,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: six
		},
		{
			memberId: 7,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: seven
		},
		{
			memberId: 8,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: eight
		},
		{
			memberId: 9,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: nine
		},
		{
			memberId: 10,
			memberName: 'Magnus Grimeland',
			memberTitle: 'Founder and C.E.O',
			memberImage: ten
		}
	];
	render() {
		return (
			<section className='team'>
				{this.teamMembers.map((teamMember) => (
					<div className='team__card' key={teamMember.memberId}>
						<img
							src={teamMember.memberImage}
							alt='member'
							className='team__member--image'
						/>
						<h4 className='team__member--name u-margin-top-medium'>
							{teamMember.memberName}
						</h4>
						<p className='team__member--title'>
							{teamMember.memberTitle}
						</p>
					</div>
				))}
			</section>
		);
	}
}

export default Team;
