// The Home Page
import React from 'react';

import './Index.scss';

import Jumbotron from '../../components/Jumbotron/Jumbotron';
import About from '../../components/About/About';
import Talk from '../../components/Talk/Talk';

function Index() {
	return (
		<React.Fragment>
			<Jumbotron />
			<About />
			<Talk />
		</React.Fragment>
	);
}
export default Index;
