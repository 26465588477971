import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';

import elixatec_white from '../../assets/img/elixatec_white.png';

class Navbar extends Component {
	constructor(props) {
		super();

		this.state = { menuIsChecked: false, isDesktop: false };

		this.updatePredicate = this.updatePredicate.bind(this);
	}

	componentDidMount() {
		this.updatePredicate();
		window.addEventListener('resize', this.updatePredicate);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updatePredicate);
	}

	updatePredicate() {
		this.setState({ isTablet: window.innerWidth < 480 });
	}
	// Change State of input menu
	toggleMenu() {
		const isTablet = this.state.isTablet;
		isTablet
			? this.setState({
					menuIsChecked: !this.state.menuIsChecked,
			  })
			: this.setState({
					menuIsChecked: false,
			  });
	}
	render() {
		return (
			<div className={'navbar'}>
				{/* Logo */}
				<Link
					to={'/'}
					className={'navbar__list-link'}
					onClick={() => this.toggleMenu()}>
					<img
						className={'navbar__logo-img'}
						src={elixatec_white}
						alt=''
					/>
				</Link>

				{/* Hamburger Menu  */}
				{/* NOTE: THIS ORDER STRICTLY MATTERS */}
				<input
					className={'menu-btn'}
					type='checkbox'
					id='menu-btn'
					onChange={() => this.toggleMenu()}
					checked={this.state.menuIsChecked}
				/>
				<label className={'menu-icon'} htmlFor='menu-btn'>
					<span className='navicon'></span>
				</label>

				{/* Menu */}

				<ul className={'navbar__list'}>
					<li className={'navbar__list-item'}>
						<Link
							to={'/'}
							className={'navbar__list-link'}
							onClick={() => this.toggleMenu()}>
							Home
						</Link>
					</li>
					<li className={'navbar__list-item'}>
						<a
							href={'/#about'}
							className={'navbar__list-link'}
							onClick={() => this.toggleMenu()}>
							About
						</a>
					</li>
					{/* <li className={'navbar__list-item'}>
						<Link
							to={'team'}
							className={'navbar__list-link'}
							onClick={() => this.toggleMenu()}>
							team
						</Link>
					</li> */}
					<li className={'navbar__list-item'}>
						<Link
							to={'work'}
							className={'navbar__list-link'}
							onClick={() => this.toggleMenu()}>
							Work
						</Link>
					</li>
					{/* <li className={'navbar__list-item'}>
						<Link
							to={'contact'}
							className={'navbar__list-link'}
							onClick={() => this.toggleMenu()}>
							Contact
						</Link>
					</li> */}

					{/* Start a Project BTN */}
					<li className={'navbar__list-item'}>
						<Link
							to={'startProject'}
							className={
								'navbar__list-link btn btn-cta u-nav-link-hover'
							}
							onClick={() => this.toggleMenu()}>
							Start a Project
						</Link>
					</li>
				</ul>
			</div>
		);
	}
}

export default Navbar;
