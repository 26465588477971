import React from 'react';

import { Link } from 'react-router-dom';

import './Jumbotron.scss';

import jumbo_illustration from '../../assets/illustrations/landing.svg';

const Jumbotron = () => {
	return (
		<div className={'jumbotron'}>
			<div className={'jumbotron__cta'}>
				<h1
					className={
						'heading__main heading__main--white u-margin-bottom-medium'
					}>
					We Help Businesses make the Digital Leap
				</h1>
				<p
					className={
						'text__content--cta text__content--regentGray u-margin-bottom-medium '
					}>
					Technology is an integral component of every business that
					is influenced and guided by corporate strategy. At Elixatec,
					we take a unique approach to IT by working with business
					leaders to understand the organizations strategic goals as a
					precursor to determining which technology capabilities,
					systems and support are required to be successful.
				</p>
				<div className={'jumbotron__cta-group'}>
					<Link to='/work' className={'btn btn-cta'}>
						Explore Our Work
					</Link>
				</div>
			</div>
			<div className={'jumbotron__illustration'}>
				<img
					src={jumbo_illustration}
					className={`jumbotron__illustration-img`}
					alt='Illustration'
				/>
			</div>
		</div>
	);
};

export default Jumbotron;
