import React, { Component } from 'react';
import './Talk.scss';

class Talk extends Component {
	render() {
		return (
			<section className='talk'>
				<div className='talk__item u-center-text'>
					<div className='u-center-text'>
						<p className='heading__secondary heading__secondary--white u-margin-bottom-small'>
							Got a Project?{' '}
						</p>
						<p className='heading__secondary heading__secondary--regentGray'>
							Let's Talk, Our Customer representatives are always
							ready to answer you via chat or mail.
						</p>
						<p className='text__content--normal text__content--boulder u-margin-top-medium'>
							info@elixatec.co.ke
						</p>
					</div>
				</div>
			</section>
		);
	}
}
export default Talk;
