import React, { Component } from 'react';
import sprite from '../../assets/icons/sprite.svg';
import './About.scss';

// Feautures Component
import Feautures from '../Features/Features';

class About extends Component {
	aboutItems = [
		{
			aboutId: 1,
			aboutTitle: 'Our Mission',
			aboutDescription:
				'To meet the changing demands in the digital revolution through our passion and commitment to our clients. ',
			aboutIcon: 'icon-target'
		},

		{
			aboutId: 2,
			aboutTitle: 'Our Vision',
			aboutDescription:
				'To simplify your IT environment through innovative technologies to reach and maximize your business dreams. ',
			aboutIcon: 'icon-telescope'
		},

		{
			aboutId: 3,
			aboutTitle: 'Our Value',
			aboutDescription:
				'Our aim was to build a company with a clear view and strategy that will constantly look ahead and keep operating at the forefront of technology developments. We encourage Innovation to meet challenges. ',
			aboutIcon: 'icon-diamond'
		},
		{
			aboutId: 4,
			aboutTitle: 'Business Goals and Objectives',
			aboutDescription:
				'Technology through automation is definitely one of the most disruptive sources. Through partnerships with our clients we positively respond to the challenges of our times and provide expert, state of the art solutions that lead straight to the point of its customer needs.',
			aboutIcon: 'icon-flag1'
		}
	];
	render() {
		return (
			<section className='about' id='about'>
				<div className='u-center-text'>
					<h2 className='heading__main heading__main--white u-margin-bottom-medium'>
						All Work, All Play
					</h2>
					<p className='text__content--cta text__content--regentGray'>
						Elixatec Limited was founded in 2018 and constitutes an
						independent, private company of consulting services in
						the sectors of core technology, fintech, digital
						disruption and international collaborations. Each of our
						senior consultants bring on board over 15 years’
						experience in design and delivery of innovative and
						large-scale technologies in East Africa.
					</p>
				</div>
				<Feautures />
				<div className='about__flex'>
					{this.aboutItems.map((aboutItem) => (
						<div
							className={`about__card about__card--${aboutItem.aboutId}`}
							key={aboutItem.aboutId}>
							<svg className='about__icon '>
								<use
									xlinkHref={`${sprite}#${aboutItem.aboutIcon}`}
								/>
							</svg>
							<h3 className='heading__secondary heading__secondary--white u-margin-bottom-small'>
								{aboutItem.aboutTitle}
							</h3>
							<p className='text__content--normal text__content--regentGray'>
								{aboutItem.aboutDescription}
							</p>
						</div>
					))}
				</div>
			</section>
		);
	}
}

export default About;
